import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as firebase from 'firebase/app';
import 'firebase/firestore';

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {


    constructor(
        private afs: AngularFirestore
    ) { }
    

    async update_survey_responses(docId, partialData) {
        const docRef = this.afs.collection<any>("survey-responses").doc(docId).ref;

        return docRef.update(partialData).then(() => {
            console.log(`document ${docId} upddated`)
        })
    }

    async save_survey_responses(docId=null, dataObject) {

        if(docId) {
            
            const docRef = this.afs.collection<any>("survey-responses").doc(docId).ref;

            return docRef.set(dataObject, { merge: true }).then(() => {
                console.log(`document ${docId} set`);
                return docRef.id;
            })

        } else {

            const colRef = this.afs.collection<any>("survey-responses").ref;

            return await colRef.add(dataObject).then(docRef => {
                console.log(`Document written, id: ${docRef.id}`);
                return docRef.id;
            })
            
        }
    }

    async archive_survey_responses(dataObject) {
        const colRef = this.afs.collection<any>("survey-responses-archive").ref;

        return await colRef.add(dataObject).then(docRef => {
            console.log(`Document written, id: ${docRef.id}`)
        })
    }

    async delete_survey_responses(docId) {
        const docRef = this.afs.collection<any>("survey-responses").doc(docId).ref;

        return docRef.delete().then(() => {
            console.log(`document ${docId} deleted`)
        })
    }

    async delete_archived_responses(docId) {
        const docRef = this.afs.collection<any>("survey-responses-archive").doc(docId).ref;

        return docRef.delete().then(() => {
            console.log(`document ${docId} deleted`)
        })
    }

    get_surveys_for_user(uid):Observable<any[]> {

        const colSnapshot = this.afs.collection<any>("survey-responses").snapshotChanges();

        return colSnapshot.pipe(
          map(action => {
            return action
            .sort(
              (a, b) => (a.payload.doc.data().responses.basicInfo.hotelName > b.payload.doc.data().responses.basicInfo.hotelName) ? 1 : -1
            )
            .map(res => {
              var data:any = res.payload.doc.data();
              data.id = res.payload.doc.id;
              if(data.uid == uid)
                return data;
            })
          })
        )

    }

    async get_survey_responses(docId) {
        return await this.afs.collection<any>("survey-responses").doc(docId).ref.get().then(docSnap => {
            if(docSnap.exists) {
                return docSnap.data();
            } 
            return null;
        });
    }

    get_all_survey_responses():Observable<any[]> {
    
        const colSnapshot = this.afs.collection<any>("survey-responses").snapshotChanges();
    
        return colSnapshot.pipe(
          map(action => {
            return action
            .sort(
              (a, b) => (a.payload.doc.data().responses.basicInfo.hotelName > b.payload.doc.data().responses.basicInfo.hotelName) ? 1 : -1
            )
            .map(res => {
              var data:any = res.payload.doc.data();
              data.id = res.payload.doc.id;
              return data;
            })
          })
        )
    
    }

    get_all_archived_responses():Observable<any[]> {
    
        const colSnapshot = this.afs.collection<any>("survey-responses-archive").snapshotChanges();
    
        return colSnapshot.pipe(
          map(action => {
            return action
            .sort(
              (a, b) => (a.payload.doc.data().responses.basicInfo.hotelName > b.payload.doc.data().responses.basicInfo.hotelName) ? 1 : -1
            )
            .map(res => {
              var data:any = res.payload.doc.data();
              data.id = res.payload.doc.id;
              return data;
            })
          })
        )
    
    }

    async save_user_profile(uid, profileData) {
        const docRef = this.afs.collection<any>("users").doc(uid).ref;

        return docRef.set(profileData, { merge: true }).then(() => {
            console.log(`document ${uid} set`)
        })
    }

    async get_user_profile(uid) {
        return await this.afs.collection<any>("users").doc(uid).ref.get().then(docSnap => {
            if(docSnap.exists) {
                return docSnap.data();
            } 
            return null;
        });
    }

    async get_admin_email_list() {
        return await this.afs.collection<any>("settings").doc("admin-emails").ref.get().then(docSnap => {
            if(docSnap.exists) {
                return docSnap.data();
            } 
            return null;
        });
    }

    async get_notif_email_list() {
        return await this.afs.collection<any>("settings").doc("notif-emails").ref.get().then(docSnap => {
            if(docSnap.exists) {
                return docSnap.data();
            } 
            return null;
        });
    }

    async add_admin_email(email) {
        const docRef = this.afs.collection<any>("settings").doc("admin-emails").ref;

        return docRef.update({
            emailList: firebase.firestore.FieldValue.arrayUnion(email)
        }).then(() => {
            console.log(`emailList updated with ${email}!`)
        });
    }

    async add_email(email, docId) {
        const docRef = this.afs.collection<any>("settings").doc(docId).ref;

        return docRef.update({
            emailList: firebase.firestore.FieldValue.arrayUnion(email)
        }).then(() => {
            console.log(`emailList for ${docId} updated with ${email}!`)
        });
    }

    async remove_admin_email(email) {
        const docRef = this.afs.collection<any>("settings").doc("admin-emails").ref;

        return docRef.update({
            emailList: firebase.firestore.FieldValue.arrayRemove(email)
        }).then(() => {
            console.log(`emailList updated with ${email}!`)
        });
    }

    async remove_email(email, docId) {
        const docRef = this.afs.collection<any>("settings").doc(docId).ref;

        return docRef.update({
            emailList: firebase.firestore.FieldValue.arrayRemove(email)
        }).then(() => {
            console.log(`emailList for ${docId} updated with ${email}!`)
        });
    }

    async get_sender_email() {
        return await this.afs.collection<any>("settings").doc("sender-email").ref.get().then(docSnap => {
            if(docSnap.exists) {
                return docSnap.data();
            } 
            return null;
        });
    }

    async get_notification_params() {
        return await this.afs.collection<any>("settings").doc("notification-params").ref.get().then(docSnap => {
            if(docSnap.exists) {
                return docSnap.data();
            } 
            return null;
        });
    }

    async update_notification_params(partialData) {
        const docRef = this.afs.collection<any>("settings").doc("notification-params").ref;

        return docRef.update(partialData).then(() => {
            console.log(`notification params upddated`)
        })
    }

    async save_email_doc(emailData) {
        const colRef = this.afs.collection<any>("mails").ref;

        return await colRef.add(emailData).then(docRef => {
            console.log(`Mails document written, id: ${docRef.id}`)
            return `${docRef.id}`;
        })
    }

    watch_email_doc(docId):Observable<any> {
        return this.afs.collection<any>("mails").doc(docId).valueChanges().pipe( map(docData => docData) );
    }

}
