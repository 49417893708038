import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class UserGuard implements CanActivate {

  constructor(
    private router: Router
    ) {}
  //Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree
canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

    const user = JSON.parse( localStorage.getItem('user') )
    
    if(user==null) {
        this.router.navigate(['login']);
        return false;
    } else {
      if(user.emailVerified) {
        return true;
      } else {
        this.router.navigate(['verify-email']);
        return false;
      }
    }

  }
  
}
