import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { FirebaseService } from './firebase-service';


@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {

  constructor(
    private router: Router,
    private fbs: FirebaseService
    ) {}
  //Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree
async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {

    const userProfile = JSON.parse( localStorage.getItem('user-profile') )
    
    if(userProfile==null) {
        this.router.navigate(['login']);
        return false;
    } else {
        if (userProfile.isAdmin) {
            return true
        } else {
            this.router.navigate(['']);
            return false;
        }
    }

  }
  
}
