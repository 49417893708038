import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AdminGuard } from './admin-guard';
import { UserGuard } from './user-guard';

const routes: Routes = [
  {
    path: 'survey',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule),
    canActivate: [UserGuard]
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'verify-email',
    loadChildren: () => import('./verify-email/verify-email.module').then( m => m.VerifyEmailPageModule)
  },
  {
    path: 'welcome',
    loadChildren: () => import('./welcome/welcome.module').then( m => m.WelcomePageModule),
    canActivate: [UserGuard]
  },
  {
    path: 'responses',
    loadChildren: () => import('./responses/responses.module').then( m => m.ResponsesPageModule),
    canActivate: [AdminGuard]
  },
  {
    path: 'energy-calculator',
    loadChildren: () => import('./energy-calc-modal/energy-calc-modal.module').then( m => m.EnergyCalcModalPageModule)
  },
  {
    path: 'waste-calc-modal',
    loadChildren: () => import('./waste-calc-modal/waste-calc-modal.module').then( m => m.WasteCalcModalPageModule)
  },
  {
    path: 'sus-scores',
    loadChildren: () => import('./sus-scores/sus-scores.module').then( m => m.SusScoresPageModule),
    canActivate: [UserGuard]
  },
  {
    path: 'responses-detail-modal',
    loadChildren: () => import('./responses-detail-modal/responses-detail-modal.module').then( m => m.ResponsesDetailModalPageModule)
  },
  {
    path: '**',
    redirectTo: 'welcome',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
