// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyCPBdXhqOi2B5aJpNjFfUpuUAlMWrWhX7c",
    authDomain: "hotel-onboarding.firebaseapp.com",
    databaseURL: "https://hotel-onboarding.firebaseio.com",
    projectId: "hotel-onboarding",
    storageBucket: "hotel-onboarding.appspot.com",
    messagingSenderId: "1088212748969",
    appId: "1:1088212748969:web:18ff3a1454ba5b4380f7b8",
    measurementId: "G-1G0MRBKM6C"
  },
  firebaseConfig_staging: {
    apiKey: "AIzaSyD209g_ggd-z6EJD_nlrFQe2Q4QB9D0oKY",
    authDomain: "hsr-staging-ddedc.firebaseapp.com",
    projectId: "hsr-staging-ddedc",
    storageBucket: "hsr-staging-ddedc.appspot.com",
    messagingSenderId: "451703284170",
    appId: "1:451703284170:web:c5f6b4be3dfaa7ac58af33"
  },
  wayajEndpoints: {
    baseURL: "https://us-central1-wayaj-191117.cloudfunctions.net",
    baseURL_staging: " https://us-central1-wayaj-staging-260218.cloudfunctions.net",
    updateScores: "updateHotelScore",
    mapHotelId: "mappedHsrHotelId",
    secret: "AIzaSyAnATh1Z2EDTRltTtj8Ph_2mqzm1AmVXfA"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
